<template>
  <div class="bg-snow-white fc-b noto-sans fs-11" style="margin: 0 auto;">

    <div class="container py-5">
      <div style="border-bottom: solid 2px gray;">
        <h3 class="mb-2">스펙북 등록</h3>
      </div>
      <div class="my-3">
        <div class="fc-b bg-w px-4 py-4" style="border: solid 1px #dadada; border-radius: 10px;">

          <b-form @submit="onSubmit">
            
            <b-form-group label="상품 대표 이미지 *" label-for="" description="" class="mb-3" required>
              <div class="p-2 mb-1">
                <div id="img-data" class="display-base" style="width: 184px; height: 184px; border: solid 1px #bbbbbb; justify-content: space-around;">
                  <img v-if="resizedImageDataUrl" :src="resizedImageDataUrl" alt="대표이미지" width="180px" height="180px">
                  <p v-else>대표 이미지 미리보기</p>
                </div>
              </div>
              <div>
                <b-form-file
                  v-model="prdImageFile"
                  :multiple=false
                  class="mw-5"
                  placeholder="상품 대표 이미지를 등록해주세요."
                  accept="image/*"
                />
                <p class="ml-1 fs-10 fc-r">ⓘ 이미지는 최소 500x500 이상 정사이즈로 등록하셔야 깨지지 않습니다.</p>
              </div>
            </b-form-group>

            <b-form-group label="상품명 *" label-for="" description="" class="mb-3" required>
              <b-form-input v-model="formData.prdName" placeholder="상품명을 입력하세요."></b-form-input>
            </b-form-group>
            <b-form-group label="브랜드 *" label-for="" description="" class="mb-3" required>
              <b-form-input v-model="formData.brand" placeholder="제품 브랜드를 입력해주세요."></b-form-input>
            </b-form-group>
            <b-form-group label="상품규격 *" label-for="" description="" class="mb-3" required>
              <b-form-input v-model="formData.unit" placeholder="규격을 입력해주세요."></b-form-input>
            </b-form-group>
            <b-form-group label="판매수량(개) *" label-for="" description="" class="mb-3" required>
              <b-form-input class="mw-2" v-model="formData.qty" type="number" min="1" placeholder="판매수량을 입력해주세요."></b-form-input>
            </b-form-group>
            <b-form-group label="상품금액(원) *" label-for="" description="" class="mb-3" required>
              <b-form-input class="mw-3" v-model.number="formData.price" type="number" min="0" placeholder="상품금액을 입력해주세요"></b-form-input>
              <b-form-input class="mt-2 mw-3" id="" v-model.number="formData.discountPrice" min="0" type="number" placeholder="할인금액을 입력해주세요" :disabled="!formData.isDiscount"></b-form-input>
              <div class="display-base">
                <b-form-checkbox class="ml-1" v-model="formData.isDiscount">할인적용</b-form-checkbox>
                <span class="ml-2 fs-10" style="color: #ce0000;">(실 판매금액: {{ commify(formData.resultPrice) }}원)</span>
              </div>
            </b-form-group>

            <b-form-group label="상품 카테고리 *" label-for="" description="" class="mb-3" required>
              <b-form-select class="mb-2 mw-4" name="category" v-model="formData.category" :options="categoryOpts">
                <template #first>
                  <b-form-select-option :value="null" disabled>-- 카테고리를 선택하세요. --</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>

            <div class="display-base mb-3">
              <b-form-group class="mr-3" style="width: 30%;" label="원산지 *" label-for="" description="" required>
                <b-form-select v-model="formData.originPlace" :options="originPlaceOpts"></b-form-select>
              </b-form-group>

              <b-form-group class="mw-6" style="width: 70%" label="제조/수입사 *" label-for="" description="" required>
                <b-form-input v-model="formData.prdComp" name="prdComp" placeholder="제조사 or 수입사를 입력해주세요."></b-form-input>
              </b-form-group>
            </div>
            
            
            <div class="mb-4">
              <p class="mb-2">상품 상세내용</p>
              <div>
                <jodit-editor v-model="formData.content" :config="editorConfig"/>
              </div>
            </div>
            
            <div class="display-base" style="justify-content: flex-end;">
              <button class="mr-2 btn-howcost" style="width: 200px;" @submit="onSubmit">등록</button>
              <button class="btn-howcost-red" style="width: 120px;" @click="writeCancel">취소</button>
            </div>
          </b-form>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import store from '@/store';
import specBookType from '../../../common/specType';

import 'jodit/build/jodit.min.css';
import { JoditEditor } from 'jodit-vue';

import {
  commify,
  apiCall,
  apiMultiPart,
  resizeImageFix,
  resizeImageAuto,
} from '../../../common/utils';

export default {
  name: "SpecShopCreate",
  components: { JoditEditor },
  data() {
    return {
      userInfo: {},
      isLogin: false,
      formData: {
        prdImg: '',
        prdName: '',
        brand: '',
        unit: '',
        qty: '',
        price: 0,
        isDiscount: false,
        discountPrice: 0,
        resultPrice: 0,
        category: null,
        originPlace: 'korea',
        prdComp: '',
        content: '',
      },
      prdImageFile: [],
      categoryOpts: specBookType.CategoryOpts,
      originPlaceOpts: specBookType.OriginPlaceOpts,
      accessToken: '',

      resizedImage: null,
      resizedImageDataUrl: null,
    }
  },

  watch: {
    'formData.price': 'resultPriceCal',
    'formData.discountPrice': 'resultPriceCal',
    'formData.isDiscount': 'resultPriceCal',
    'prdImageFile': 'imageSave',
  },

  async created() {
    const r = await apiCall('GET', `/user/auth/get-token`);
    if (r.result) {
      this.accessToken = r.result;
      this.isLogin = store.dispatch('userStore/REFRESH', this.accessToken);
      if (this.isLogin) this.setUserInfo;
    }
    // console.log("userInfo", this.userInfo.user);
  },

  mounted() {
  },

  computed: {
    setUserInfo() {
      this.userInfo = store.state.userStore;
    },

    editorConfig() {
      const token = this.accessToken;
      
      const protocol = window.location.protocol; // 현재 프로토콜
      const host = window.location.hostname; // 현재 접속 호스트
      const wlPort = window.location.port; // 현재 접속 포트
      const port = (wlPort==="8888") ? '8080':'443';

      const apiServer = `${protocol}//${host}:${port}`;

      return {
        uploader: {
          filesVariableName: function(e) {
            return 'files';
          },
          url: `${apiServer}/api/spec-shop/board-image/upload`,
          format: 'json',
          method: 'POST',
          headers: { 
            'Authorization': token,
          },
          baseurl: `${apiServer}`,

          isSuccess: function (resp) {
            return resp.data.code === 220;
          },
          process: function (resp) {
            return resp.data;
          },
        },
        placeholder : '상품 상세내용을 입력해주세요 ..',
        minHeight: '500px',

      };
    },
  },

  methods: {
    commify(n) { return commify(n); },
    resultPriceCal() {
      this.formData.resultPrice = (this.formData.isDiscount) ? (this.formData.price - this.formData.discountPrice) : this.formData.price;
    },

    async writeCancel(evt) {
      evt.preventDefault();
      this.$router.go(-1);
      // window.history.back();
    },

    // 대표 이미지 저장
    async imageSave() {
      try {
        this.resizedImage = await resizeImageFix(this.prdImageFile, 500, 500);
        // console.log('this.resizedImage ======> ', this.resizedImage);

        const reader = new FileReader();
        reader.onload = () => {
          this.resizedImageDataUrl = reader.result;
        }
        reader.readAsDataURL(this.prdImageFile);

      } catch (error) {
        console.error(error);
      }
    },
    
    async onSubmit(evt) {
      evt.preventDefault();
      
      let formData = new FormData();
      formData.append("file", this.resizedImage.resizedBlob, this.resizedImage.name);

      try {
        const r = await apiMultiPart(`/api/spec-shop/main-image/upload`, formData, this.accessToken);
        if (r.code === 200) {
          this.formData.prdImg = r.result;
        } else {
          alert("대표 이미지가 업로드 되지 않았습니다. 이미지를 재등록 해주세요.");
          return ;
        }

        // 등록
        const rr = await apiCall('POST', `/api/spec-shop/create/item`, this.formData, this.accessToken);
        if (rr.code === 200) {
          alert(rr.result);
          window.location.href = '/#/spec-shop';
        } else if (rr.code === 999) {
          alert("내 계정 정보에 필수 정보가 등록되어 있지 않습니다.");
        } else {
          console.error(rr);
          alert("스펙북 등록에 실패하였습니다.");
          return ;
        }
      } catch (error) {
        console.error(error);
        alert("[ERROR] 스펙북 등록에 실패하였습니다.");
      }
      
      // console.log('this.formData ======> ', this.formData);
    },

  }
}
</script>

<style>
p {
  margin-bottom: 0;
}
.jodit-toolbar-button_file, .jodit-ui-group__file {
  /* jodit 파일첨부 기능 가리기 */
  display: none !important;
}
.jodit-status-bar-link {
  /* 홈페이지 이동 클릭 방지 */
  display: none !important;
}
</style>